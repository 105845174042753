﻿﻿import { AxiosError } from 'axios'
import router from '@/router'
import rg4js from 'raygun4js'
import { useCommonStore } from '@/stores/CommonStore'
import i18n from '@/i18n'

type ErrorWithMessage = {
  message: string
}
type customData = {
  action?: string
}

export default {
  handleError(
    error: unknown,
    action: string,
    showErrorMessage?: boolean,
    customErrorMessage?: string
  ) {
    // checking for promise error on 401s before interceptor does it's job
    if (error == 'Error' || error == undefined) return
    // double check for 401 errors from Axios
    if (
      this.isAxiosErorr(error) &&
      error.response &&
      error.response.status === 401
    ) {
      // should not make it here. Axios response interceptor should handle 401 errors, so just send the user to logout
      router.push('logout')
    }
    // Checks to see if user is offline
    else if (this.isErrorObj(error) && error.message == 'Network Error') {
      const commonStore = useCommonStore()
      commonStore.setErrorModal({
        showErrorModal: true,
        title: i18n.global.t('errorModal.header'),
        errorMessage: showErrorMessage ? customErrorMessage : '',
        showGenericError: !showErrorMessage || false,
        showFixError: showErrorMessage || false
      })

      return
    }
    // if we send a custom error message, display the message, but don't send to Raygun
    else if (showErrorMessage && customErrorMessage) {
      const commonStore = useCommonStore()
      commonStore.setErrorModal({
        showErrorModal: true,
        title: i18n.global.t('errorModal.header'),
        errorMessage: customErrorMessage,
        showGenericError: !showErrorMessage || false,
        showFixError: showErrorMessage || false
      })
      return
    }
    // else send to raygun and display to user if showErrorMessage == true
    else {
      // send to raygun
      const customData: customData = {}

      if (action) customData.action = action
      if (this.isErrorObj(error)) {
        rg4js('send', {
          error: error,
          customData: [customData]
        })
      } else {
        rg4js('send', {
          error: new Error(`Error not formatted correctly: ${error}`),
          customData: [customData]
        })
      }
      const commonStore = useCommonStore()
      commonStore.setErrorModal({
        showErrorModal: true,
        title: i18n.global.t('errorModal.header'),
        errorMessage: '',
        showGenericError: true,
        showFixError: false
      })
    }
  },
  isAxiosErorr(error: unknown): error is AxiosError {
    return error instanceof AxiosError
  },
  isErrorObj(error: unknown): error is Error {
    if (error && typeof error === 'object') {
      return 'name' in error && 'message' in error
    }
    return false
  },
  isErrorWithMessage(error: unknown): error is ErrorWithMessage {
    return (
      typeof error === 'object' &&
      error !== null &&
      'message' in error &&
      typeof (error as Record<string, unknown>).message === 'string'
    )
  },
  toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
    if (this.isErrorWithMessage(maybeError)) return maybeError

    try {
      return new Error(JSON.stringify(maybeError))
    } catch {
      // fallback in case there's an error stringifying the maybeError
      // like with circular references for example.
      return new Error(String(maybeError))
    }
  },
  getErrorMessage(error: unknown) {
    return this.toErrorWithMessage(error).message
  }
}