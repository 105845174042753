<template>
  <modal-v2-component
    :modalActive="modalActiveRef"
    :showFooter="false"
    :title="t('accountSettings.generalInfo.mfa.label')"
    @close="close()"
  >
    <template #content>
      <form class="p-3" role="form" @submit.prevent="onSubmit" >
        <div class="mfa-modal">
          <header class="">
            <p class="mb-1 text-center" style="max-width: 40ch; margin: 0 auto">
              <Translation keypath="accountSettings.generalInfo.mfa.modal.description"><strong>{{ redactedPhoneNumber }}</strong>.</Translation><br>
              <Translation keypath="accountSettings.generalInfo.mfa.modal.helpText">
                <a
                  href="https://support.talkingparents.com/hc/en-us"
                  target="_self"
                  class="font-size-1 brand underline text-center mt-3"
                >
                  {{ t('accountSettings.generalInfo.mfa.modal.helpCenter') }}
                </a>
              </Translation>
            </p>
          </header>
          <code-input
            @codeResult="handleCodeResult"
            @focusedInput="resetMessages()"
          />
          <span
            v-if="mfaErrorMsg.length > 0"
            class="font-size-0 error text-center pt-00"
          >
            {{ mfaErrorMsg }}
          </span>
        </div>
        <footer class="stack size-1 mt-2">
          <button
            ref="ConfirmEnableMfaSmsButton"
            type="submit"
            class="btn primary"
            :disabled="!meta.dirty || !meta.valid || state.submitting || isLockedOut"
          >
          <loading :loading="state.submitting"/>
            {{ t('accountSettings.generalInfo.mfa.modal.cta') }}
          </button>
          <button
            id="codeResendButton"
            type="button"
            class="btn tertiary brand"
            @click="resendCode()"
            style="font-weight: var(--font-weight-1);"
            :disabled="isLockedOut || state.submitting"
            >
              {{ t('accountSettings.generalInfo.mfa.modal.sendAnotherCode') }}
          </button>
          <span
            v-if="state.showResendSuccess"
            class="bg-lt-blue code-resent mt-2 text-center ml-auto mr-auto"
          >
            {{ t('accountSettings.generalInfo.mfa.modal.resentText', { phoneNumber: redactedPhoneNumber }) }}
          </span>
        </footer>
      </form>
    </template>
  </modal-v2-component>
</template>
<script lang="ts" setup>
import { toRef, reactive, watch, ref, onUnmounted } from 'vue'
import { useLoginStore } from '@/stores/LoginStore'
import { storeToRefs } from 'pinia'
import { useForm } from 'vee-validate'
import { useRoute } from 'vue-router'
import type { ILoginRequest } from '@/models/interfaces'
import ErrorHelper from '@/exports/error'
import CodeInput from '@/components/CodeInputComponent.vue'
import ModalV2Component from '@/components/ModalV2Component.vue'
import Loading from '@/components/library/Loading.vue'
import { useI18n, Translation } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

const props = defineProps({
  modalActive: {
    type: Boolean,
    required: true
  },
  email: {
    type: String,
    required: true
  },
  password: {
    type: String,
    required: true
  }
})

const modalActiveRef = toRef(props, 'modalActive')

const route = useRoute()

const loginStore = useLoginStore()
const { redactedPhoneNumber, mfaErrorMsg, isLockedOut } = storeToRefs(loginStore)
const { login } = loginStore

const state = reactive({
  mfaErrorMsg: '',
  codeResult: '',
  submitting: false,
  showResendSuccess: false,
})

const { handleSubmit, meta, handleReset } = useForm()

const onSubmit = handleSubmit(() => {
  submitAuthCode()
})

onUnmounted(() => {
  isLockedOut.value = false
  resetMessages()
})

async function resendCode() {
  state.submitting = true

  const payload: ILoginRequest = {
    username: props.email,
    password: props.password,
    returnUrl: ''
  }
  await login(payload)
  state.showResendSuccess = true
  state.submitting = false

  resetMfa()
}

function resetMfa() {
  state.submitting = false
  handleReset()
}

function handleCodeResult(value: string) {
  state.showResendSuccess = false
  state.codeResult = value
}

watch(
  () => mfaErrorMsg.value,
  async (val) => {
    if (val.length) resetMfa()
  }
)

function submitAuthCode() {
  state.showResendSuccess = false

  if (state.submitting) return
  state.submitting = true
  try {
    const payload: ILoginRequest = {
      username: props.email,
      password: props.password,
      mfaCode: state.codeResult,
      returnUrl: route.query.returnTo?.toString()
    }

    emit('submitLogin', payload)
  } catch (e) {
    ErrorHelper.handleError(e, 'submitAuthCode', false, '')
    state.submitting = false
    return
  }
}

// MFA
const emit = defineEmits<{
  (e: 'close'): void
  (e: 'submitLogin', payload: ILoginRequest): void
}>()

function close() {
  resetMfa()
  emit('close')
}

function resetMessages() {
  state.showResendSuccess = false

  if (!isLockedOut.value) {
    mfaErrorMsg.value = ''
  }
}
</script>
<style lang="scss" scoped>
.mfa-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  & p {
    font-size: var(--size-1);
  }
}

:deep(.modal-inner) {
  @media (min-width: 48em) {
    width: 600px;
  }
}

strong {
  --base-weight: 800;
  font-weight: 800;
}

.code-resent {
  border-radius: var(--radius-4);
  font-weight: var(--font-weight-2);
  padding: var(--size-1) var(--size-4);
  width: fit-content;
}
  
:deep(.dialog-header) {
  padding-inline: var(--size-3);
}

.btn.tertiary:disabled {
  cursor: not-allowed;
  opacity: .5;
}
</style>
