import { useRegisterSW } from 'virtual:pwa-register/vue'
import { ref, onMounted, onUnmounted } from 'vue'

const intervalMS =
  import.meta.env.MODE === 'production' ? 5 * 60 * 1000 : 30 * 1000

// Reactive properties for service worker state
const isOfflineReady = ref(false)
const hasUpdateAvailable = ref(false)

let intervalId: number | undefined

export function setupServiceWorker() {
  const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
    immediate: true, // Register the service worker immediately
    onRegisteredSW(swUrl: string, registration) {
      console.log(`Service Worker registered at: ${swUrl}`)
      registration &&
        setInterval(async () => {
          console.log(
            'Checking for service worker updates from onRegisteredSW...'
          )
          await registration.update()
        }, intervalMS) // Check every 20 seconds for testing
    },
    onNeedRefresh() {
      console.log('Service Worker needs refresh')
      localStorage.setItem('newVersionAvailable', 'true')
    },
    onOfflineReady() {
      console.log('App is ready for offline use')
    }
  })

  // Update reactive properties
  isOfflineReady.value = offlineReady.value
  hasUpdateAvailable.value = needRefresh.value

  return {
    updateServiceWorker
  }
}
