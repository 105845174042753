import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import i18nHelpers from '@/i18n/i18nHelpers'
import i18n from '@/i18n'
import AuthLayout from '@/layouts/AuthLayout.vue'
import TPLayout from '@/layouts/TPLayout.vue'
import MessagesLayout from '@/layouts/MessagesLayout.vue'
import { br } from '@/plugins/trackerPlugin'
const AccountSettingsLayout = () =>
  import('@/layouts/AccountSettingsLayout.vue')
const CalendarLayout = () => import('@/layouts/CalendarLayout.vue')
const MoneyTransferLayout = () => import('@/layouts/MoneyTransferLayout.vue')
const InfoLibraryLayout = () => import('@/layouts/InfoLibraryLayout.vue')
const JournalLayout = () => import('@/layouts/JournalLayout.vue')
const RecordsLayout = () => import('@/layouts/RecordsLayout.vue')
const AttachmentsLayout = () => import('@/layouts/AttachmentsLayout.vue')
const SignupLayout = () => import('@/layouts/SignupLayout.vue')
const ContactUsLayout = () => import('@/layouts/ContactUsLayout.vue')
const NotFoundLayout = () => import('@/layouts/NotFoundLayout.vue')
const LandingPageLayout = () => import('@/layouts/LandingPageLayout.vue')
const NewsletterLayout = () => import('@/layouts/NewsletterLayout.vue')
const VaultLayout = () => import('@/layouts/VaultLayout.vue')
const CallingLayout = () => import('@/layouts/CallingLayout.vue')

import LoginPage from '@/pages/login/LoginPage.vue'
const LogoutPage = () => import('@/pages/login/LogoutPage.vue')
import ForgotEmailOrPasswordVue from '@/pages/login/ForgotEmailOrPassword.vue'
import ResetPassword from '@/pages/login/ResetPassword.vue'
const verifyEmailChange = () => import('@/pages/accounts/VerifyEmail.vue')

import Messages from '@/pages/messages/Messages.vue'
const MessagesForm = () => import('@/pages/messages/MessagesForm.vue')
const ViewMessage = () => import('@/pages/messages/ViewMessage.vue')
const MessagesWelcome = () =>
  import('@/pages/messages/components/messagesWelcome.vue')

const AccountSettings = () => import('@/pages/accounts/AccountSettings.vue')
const GeneralInfo = () => import('@/pages/accounts/GeneralInfo.vue')
const PlanDetails = () => import('@/pages/accounts/PlanDetails.vue')
const Notifications = () => import('@/pages/accounts/Notifications.vue')
const Password = () => import('@/pages/accounts/Password.vue')
const AccountableCalling = () =>
  import('@/pages/accounts/AccountableCalling.vue')
const AccountTypeStep1 = () => import('@/pages/accounts/AccountTypeStep1.vue')
const AccountSettingsExternal = () =>
  import('@/pages/accounts/AccountSettingsExternal.vue')

const CalendarChildLayout = () =>
  import('@/pages/calendar/CalendarChildLayout.vue')
const CalendarMonthView = () => import('@/pages/calendar/CalendarMonthView.vue')
const CalendarDayView = () => import('@/pages/calendar/CalendarDayView.vue')
const CalendarWeekView = () => import('@/pages/calendar/CalendarWeekView.vue')
const CalendarEntryForm = () => import('@/pages/calendar/CalendarEntryForm.vue')
const CalendarGraveyard = () => import('@/pages/calendar/CalendarGraveyard.vue')
const CalendarWelcome = () => import('@/pages/calendar/CalendarWelcome.vue')

const MoneyTransfer = () =>
  import('@/pages/moneyTransfer/MoneyTransferPage.vue')
const AddTransfer = () => import('@/pages/moneyTransfer/AddTransfer.vue')
const AddRequest = () => import('@/pages/moneyTransfer/AddRequest.vue')
const NegativeBalance = () =>
  import('@/pages/moneyTransfer/NegativeBalance.vue')
const MoneyTransferWelcome = () =>
  import('@/pages/moneyTransfer/MoneyTransferWelcome.vue')
const MfaDisabled = () => import('@/pages/moneyTransfer/MfaDisabled.vue')
const VerifyCustomer = () => import('@/pages/moneyTransfer/VerifyCustomer.vue')
const FundingSourceList = () =>
  import('@/pages/moneyTransfer/FundingSourceList.vue')
const ActivityList = () => import('@/pages/moneyTransfer/ActivityList.vue')
const ActivityDetail = () =>
  import('@/pages/moneyTransfer/activityDetailComponent.vue')
const verifyMicroDepositPlaid = () =>
  import('@/pages/moneyTransfer/verifyMicroDepositPlaidComponent.vue')
const ExpressPayIntroPage = () =>
  import('@/pages/moneyTransfer/ExpressPayIntroPage.vue')

const Journal = () => import('@/pages/journal/Journal.vue')
const JournalForm = () => import('@/pages/journal/JournalForm.vue')

const RecordsHome = () => import('@/pages/records/RecordsHome.vue')
const RecordsPurchase = () =>
  import('@/pages/records/PrintedPurchaseSuccess.vue')
const Records = () => import('@/pages/records/Records.vue')
const PrintedPurchaseSuccess = () =>
  import('@/pages/records/PrintedPurchaseSuccess.vue')
const PdfPurchaseSuccess = () =>
  import('@/pages/records/PdfPurchaseSuccess.vue')

const Attachments = () => import('@/pages/attachments/Attachments.vue')

const ContactUs = () => import('@/pages/contact-us/ContactUsPage.vue')

import { useLoginStore } from '@/stores/LoginStore'
import { useCommonStore } from '@/stores/CommonStore'
import { useSignupStore } from '@/stores/SignupStore'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import { useCallingStore } from '@/stores/CallingStore'

import decodeJwtService from '@/services/decodeJwtService'
const InfoLibrary = () => import('@/pages/infoLibrary/InfoLibrary.vue')
const AccountSettingsTabs = () =>
  import('@/pages/accounts/AccountSettingsTabs.vue')
const EditName = () => import('@/pages/accounts/components/editName.vue')
const EditPhoneNumber = () =>
  import('@/pages/accounts/components/editPhoneNumber.vue')
const EditEmail = () => import('@/pages/accounts/components/editEmail.vue')
const EditDateTimeSettings = () =>
  import('@/pages/accounts/components/editDateTimeSettings.vue')
import constants from '@/exports/constants'
import Appearance from '@/pages/accounts/Appearance.vue'

const SignupEmail = () => import('@/pages/signup/SignupEmail.vue')
const SignupInfo = () => import('@/pages/signup/SignupInfo.vue')
const SignupSecurity = () => import('@/pages/signup/SignupSecurity.vue')
const SignupMatchInfo = () => import('@/pages/signup/SignupMatchInfo.vue')
const SignupMatch = () => import('@/pages/signup/SignupMatch.vue')
const SignupInfoEdit = () => import('@/pages/signup/SignupInfoEdit.vue')
const SignupCoparentInfoEdit = () =>
  import('@/pages/signup/SignupCoparentInfoEdit.vue')
const SignupChildInfoEdit = () =>
  import('@/pages/signup/SignupChildInfoEdit.vue')
const SignupPasswordEdit = () => import('@/pages/signup/SignupPasswordEdit.vue')
const SignupPhoneEdit = () => import('@/pages/signup/SignupPhoneEdit.vue')

const NotFound = () => import('@/pages/notFound/NotFound.vue')
const NewsletterSubscribe = () =>
  import('@/pages/subscribe/NewsletterSignup.vue')

const ShareVault = () => import('@/pages/vault/ShareVault.vue')
const Vault = () => import('@/pages/vault/Vault.vue')

const Calling = () => import('@/pages/calling/Calling.vue')
const VideoCall = () => import('@/pages/calling/VideoCall.vue')
const VideoDiagnostics = () =>
  import('@/pages/calling/components/VideoCallDiagnostics.vue')
const PdfDownload = () => import('@/pages/pdfDownload/PdfDownload.vue')
const NewsletterWelcome = () =>
  import('@/pages/subscribe/NewsletterWelcome.vue')
const NewsletterUpdatePreferences = () =>
  import('@/pages/subscribe/UpdatePreferences.vue')

declare const window: any

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/moneyTransfer', redirect: { name: 'moneyTransfer' } },
    { path: '/', redirect: { name: 'messages' } },
    {
      path: '/minSuccess/:sessionId',
      redirect: (to) => {
        const callingStore = useCallingStore()
        const accountSettings = useAccountSettingsStore()

        // record
        const sessionId =
          to.params.sessionId instanceof Array
            ? to.params.sessionId[0]
            : to.params.sessionId || ''
        accountSettings.addMinSuccess(sessionId)
        accountSettings.setShowAddMinutesSuccessToast(true)

        // show modals when needed
        if (to.query.video === 'true') {
          callingStore.tryShowVideoCall()
        }

        if (to.query.audio === 'true') {
          callingStore.setShowAudioCall(true)
        }

        // send to prev route
        if (to.query.p) {
          if (to.query.p.includes('calendar/entry')) {
            return { name: 'calendar', query: {} }
          } else {
            return { path: `${to.query.p}`, query: {} }
          }
        } else {
          return { name: 'accountableCalling', query: {} }
        }
      }
    },
    {
      path: '/minCancel/:sessionId',
      redirect: (to) => {
        const callingStore = useCallingStore()
        const accountSettings = useAccountSettingsStore()

        // cancel
        const sessionId =
          to.params.sessionId instanceof Array
            ? to.params.sessionId[0]
            : to.params.sessionId || ''
        accountSettings.cancelProcessing(sessionId)

        // show modals when needed
        if (to.query.video === 'true') {
          callingStore.tryShowVideoCall()
        }

        if (to.query.audio === 'true') {
          callingStore.setShowAudioCall(true)
        }

        // send to prev route
        if (to.query.p) {
          if (to.query.p.includes('calendar/entry')) {
            return { name: 'calendar', query: {} }
          } else {
            return { path: `${to.query.p}`, query: {} }
          }
        } else {
          return { name: 'accountableCalling', query: {} }
        }
      }
    },
    {
      path: '/legal/subpoena',
      alias: [
        '/legal/subpoenaRecordRequestConfirmation',
        '/legal/subpoenaPayment',
        '/legal/subpoenaRecordRequestPaymentConfirmation'
      ],
      redirect: () => {
        window.location.replace('https://legal.talkingparents.com/subpoenas')
        return {}
      }
    },
    {
      path: '/signup',
      component: SignupLayout,
      children: [
        {
          name: 'signup',
          path: '/signup',
          component: SignupEmail
        },
        {
          name: 'signupInfo',
          path: '/info',
          component: SignupInfo,
          meta: {
            allowBack: true
          },
          beforeEnter: () => {
            const signupStore = useSignupStore()
            if (
              signupStore.$state.signupEmail &&
              signupStore.$state.signupGuid &&
              signupStore.$state.verifyCode
            ) {
              return true
            } else {
              return 'signup'
            }
          }
        },
        {
          name: 'signupSecurity',
          path: '/security',
          component: SignupSecurity,
          meta: {
            requiresAuth: true,
            requiresMatched: false
          }
        },
        {
          name: 'signupMatchInfo',
          path: '/matchInfo',
          component: SignupMatchInfo,
          meta: {
            requiresAuth: true,
            requiresMatched: false
          }
        },
        {
          name: 'signupMatch',
          path: '/match',
          component: SignupMatch,
          meta: {
            requiresAuth: true,
            requiresMatched: false
          },
          beforeEnter: async () => {
            const signupStore = useSignupStore()
            await signupStore.fetchUnmatchedAccountInfo()

            if (
              signupStore.$state.unmatchedAccountInfo.coparentFirstName &&
              signupStore.$state.unmatchedAccountInfo.coparentLastName &&
              signupStore.$state.unmatchedAccountInfo.childFirstName &&
              signupStore.$state.unmatchedAccountInfo.childBirthdate
            ) {
              return true
            } else {
              return 'matchInfo'
            }
          }
        },
        {
          name: 'signupInfoEdit',
          path: '/infoEdit',
          component: SignupInfoEdit,
          meta: {
            requiresAuth: true,
            allowBack: true
          }
        },
        {
          name: 'signupCoparentInfoEdit',
          path: '/coparentInfoEdit',
          component: SignupCoparentInfoEdit,
          meta: {
            requiresAuth: true,
            allowBack: true
          }
        },
        {
          name: 'signupChildInfoEdit',
          path: '/childInfoEdit',
          component: SignupChildInfoEdit,
          meta: {
            requiresAuth: true,
            allowBack: true
          }
        },
        {
          name: 'signupPasswordEdit',
          path: '/passwordEdit',
          component: SignupPasswordEdit,
          meta: {
            requiresAuth: true,
            allowBack: true
          }
        },
        {
          name: 'signupPhoneEdit',
          path: '/phoneEdit',
          component: SignupPhoneEdit,
          meta: {
            requiresAuth: true,
            allowBack: true
          }
        }
      ]
    },
    {
      path: '/',
      component: AuthLayout,
      children: [
        {
          name: 'login',
          path: '/login',
          component: LoginPage,
          meta: {
            requiresAuth: false,
            requiresMatched: false
          }
        },
        {
          name: 'forgotEmailOrPassword',
          path: '/forgotEmailOrPassword',
          component: ForgotEmailOrPasswordVue,
          meta: {
            requiresAuth: false,
            requiresMatched: false
          }
        },
        {
          name: 'resetPassword',
          path: 'reset-password',
          component: ResetPassword,
          meta: {
            requiresAuth: false,
            requiresMatched: false
          }
        },
        {
          name: 'resetPasswordEmail',
          path: 'reset-password/:email',
          component: ResetPassword,
          meta: {
            requiresAuth: false,
            requiresMatched: false
          }
        },
        {
          name: 'logout',
          path: 'logout',
          alias: '',
          component: LogoutPage,
          meta: {
            requiresAuth: false,
            requiresMatched: false
          }
        },
        {
          name: 'logoutClearMfa',
          path: 'logout/:clearMfa',
          alias: '',
          component: LogoutPage,
          meta: {
            requiresAuth: false,
            requiresMatched: false
          }
        },
        {
          name: 'verifyEmailChange',
          path: 'verifyEmailChange',
          component: verifyEmailChange,
          props: true,
          meta: { requiresAuth: false, allowBack: true }
        }
      ]
    },
    {
      path: '/',
      component: TPLayout,
      children: [
        {
          name: 'accountSettingsLayout',
          path: 'accountSettings',
          alias: '',
          component: AccountSettingsLayout,
          props: true,
          meta: {
            requiresAuth: true,
            requiresMatched: true,
            allowRefresh: true,
            trackingName: br.appActionFeature.settings
          },
          children: [
            {
              name: 'accountSettingsHome',
              path: '/accountSettings/',
              component: AccountSettingsTabs,
              props: true,
              redirect: { name: 'generalInfo' },
              meta: { requiresAuth: true, allowBack: true },
              children: [
                {
                  name: 'generalInfo',
                  path: '/accountSettings/generalInfo',
                  component: GeneralInfo,
                  props: true,
                  meta: { requiresAuth: true, allowBack: true }
                },
                {
                  name: 'appearance',
                  path: '/accountSettings/appearance',
                  component: Appearance,
                  props: true,
                  meta: { requiresAuth: true, allowBack: true }
                },
                {
                  name: 'planDetails',
                  path: '/accountSettings/planDetails',
                  component: PlanDetails,
                  props: true,
                  meta: { requiresAuth: true, allowBack: true }
                },
                {
                  name: 'accountTypeStep1Success',
                  path: '/accountSettings/planDetails/success/:sessionId',
                  component: PlanDetails,
                  props: true,
                  meta: { requiresAuth: true, allowBack: true }
                },
                {
                  name: 'accountTypeStep1Canceled',
                  path: '/accountSettings/planDetails/canceled/:sessionId',
                  component: PlanDetails,
                  props: true,
                  meta: { requiresAuth: true, allowBack: true }
                },
                {
                  name: 'notifications',
                  path: '/accountSettings/notifications',
                  component: Notifications,
                  props: true,
                  meta: { requiresAuth: true, allowBack: true }
                },
                {
                  name: 'notificationsSmsSuccess',
                  path: '/accountSettings/notifications/success/:sessionId',
                  component: Notifications,
                  props: true,
                  meta: { requiresAuth: true, allowBack: true }
                },
                {
                  name: 'notificationsSmsCanceled',
                  path: '/accountSettings/notifications/canceled/:sessionId',
                  component: Notifications,
                  props: true,
                  meta: { requiresAuth: true, allowBack: true }
                },
                {
                  name: 'password',
                  path: '/accountSettings/password',
                  component: Password,
                  props: true,
                  meta: { requiresAuth: true, allowBack: true }
                },
                {
                  name: 'accountableCalling',
                  path: '/accountSettings/accountableCalling',
                  component: AccountableCalling,
                  meta: { requiresAuth: true, allowBack: true }
                },
                {
                  name: 'accountableCallingAddMinSuccess',
                  path: '/accountSettings/accountableCalling/success/:sessionId',
                  component: AccountableCalling,
                  meta: { requiresAuth: true, allowBack: true }
                },
                {
                  name: 'accountableCallingAddMinCanceled',
                  path: '/accountSettings/accountableCalling/canceled/:sessionId',
                  component: AccountableCalling,
                  meta: { requiresAuth: true, allowBack: true }
                }
              ]
            },
            {
              name: 'accountSettingsFull',
              path: '/accountSettings/',
              component: AccountSettings,
              props: true,
              meta: { requiresAuth: true, allowBack: true },
              children: [
                {
                  name: 'editName',
                  path: '/accountSettings/name',
                  component: EditName,
                  props: true,
                  meta: { requiresAuth: true, allowBack: true }
                },
                {
                  name: 'editPhoneNumber',
                  path: '/accountSettings/phone',
                  component: EditPhoneNumber,
                  props: true,
                  meta: { requiresAuth: true, allowBack: true }
                },
                {
                  name: 'editPhoneNumberVerify',
                  path: '/accountSettings/phone/:verify',
                  component: EditPhoneNumber,
                  props: true,
                  meta: { requiresAuth: true, allowBack: true }
                },
                {
                  name: 'editPhoneNumberEnableMFA',
                  path: '/accountSettings/phone/:enableMFA',
                  component: EditPhoneNumber,
                  props: true,
                  meta: { requiresAuth: true, allowBack: true }
                },
                {
                  name: 'editEmail',
                  path: '/accountSettings/email',
                  component: EditEmail,
                  props: true,
                  meta: { requiresAuth: true, allowBack: true }
                },
                {
                  name: 'editDateTimeSettings',
                  path: '/accountSettings/dateTime',
                  component: EditDateTimeSettings,
                  props: true,
                  meta: { requiresAuth: true, allowBack: true }
                }
              ]
            },
            {
              name: 'accountSettingsExternal',
              path: '/accountSettings/',
              component: AccountSettingsExternal,
              props: true,
              meta: { requiresAuth: true, allowBack: true },
              children: [
                {
                  name: 'accountTypeStep1',
                  path: '/accountSettings/accountTypeStep1',
                  component: AccountTypeStep1,
                  props: true,
                  meta: { requiresAuth: true, allowBack: true },
                  beforeEnter: async () => {
                    const accountSettings = useAccountSettingsStore()
                    if (
                      accountSettings.subscriptionT1 ||
                      accountSettings.subscriptionT2
                    ) {
                      return 'planDetails'
                    } else {
                      return true
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          name: 'messagesLayout',
          path: 'messages',
          alias: '',
          component: MessagesLayout,
          props: true,
          meta: {
            requiresAuth: true,
            requiresMatched: true,
            trackingName: br.appActionFeature.messages
          },
          children: [
            {
              name: 'messages',
              path: '/messages/',
              component: Messages,
              props: true,
              meta: {
                requiresAuth: true,
                requiresMatched: true,
                allowRefresh: true,
                layoutContainsAds: false,
                nameForLabels: i18n.global.t('messages.newMessageBtn'),
                addNewRouteName: 'messagesEntry'
              }
            },
            {
              name: 'messagesEntry',
              path: '/messages/new',
              component: MessagesForm,
              props: true,
              meta: {
                requiresAuth: true,
                requiresMatched: true,
                allowRefresh: true,
                layoutContainsAds: true
              }
            },
            {
              name: 'messagesEntryPhoneCall',
              path: '/messages/new/:callLogItemID',
              component: MessagesForm,
              props: true,
              meta: {
                requiresAuth: true,
                requiresMatched: true,
                allowRefresh: true,
                layoutContainsAds: true
              }
            },
            {
              name: 'messagesEntryVideoCall',
              path: '/messages/new/:videoCallItemId',
              component: MessagesForm,
              props: true,
              meta: {
                requiresAuth: true,
                requiresMatched: true,
                allowRefresh: true,
                layoutContainsAds: true
              }
            },
            {
              name: 'messagesEntryVoicemail',
              path: '/messages/new/:videoVoicemailItemID',
              component: MessagesForm,
              props: true,
              meta: {
                requiresAuth: true,
                requiresMatched: true,
                allowRefresh: true,
                layoutContainsAds: true
              }
            },
            {
              name: 'messagesWelcome',
              path: '/messages/welcome',
              component: MessagesWelcome,
              props: true,
              meta: { requiresAuth: true, allowRefresh: true }
            },
            {
              name: 'viewMessage',
              path: '/messages/view/:itemId',
              component: ViewMessage,
              props: true,
              meta: {
                requiresAuth: true,
                allowRefresh: true,
                layoutContainsAds: true
              }
            }
          ]
        },
        {
          name: 'calendarLayout',
          path: 'calendar',
          alias: '',
          component: CalendarLayout,
          props: true,
          meta: {
            requiresAuth: true,
            requiresMatched: true,
            trackingName: br.appActionFeature.calendar
          },
          children: [
            {
              name: 'calendar',
              path: '/calendar/',
              component: CalendarChildLayout,
              props: true,
              meta: { requiresAuth: true, allowRefresh: true },
              redirect: () => {
                return { name: 'calendarMonth' }
              },
              children: [
                {
                  name: 'calendarMonth',
                  path: '/calendar/month',
                  component: CalendarMonthView,
                  props: true,
                  meta: {
                    type: constants.viewTypes.month,
                    typeName: constants.viewTypeNames.month,
                    requiresAuth: true,
                    allowRefresh: true,
                    nameForLabels: 'New event',
                    addNewRouteName: 'newCalendarEntry'
                  }
                },
                {
                  name: 'calendarMonthView',
                  path: '/calendar/month/:startDate',
                  component: CalendarMonthView,
                  props: true,
                  meta: {
                    type: constants.viewTypes.month,
                    typeName: constants.viewTypeNames.month,
                    requiresAuth: true,
                    allowRefresh: true,
                    nameForLabels: 'New event',
                    addNewRouteName: 'newCalendarEntry'
                  }
                },
                {
                  name: 'calendarWithDate',
                  path: '/calendar/month/:startDate',
                  component: CalendarMonthView,
                  props: true,
                  meta: {
                    type: constants.viewTypes.month,
                    typeName: constants.viewTypeNames.month,
                    requiresAuth: true,
                    allowRefresh: true,
                    nameForLabels: 'New event',
                    addNewRouteName: 'newCalendarEntry'
                  }
                },
                {
                  name: 'calendarDayView',
                  path: '/calendar/day',
                  component: CalendarDayView,
                  props: true,
                  meta: {
                    type: constants.viewTypes.day,
                    typeName: constants.viewTypeNames.day,
                    requiresAuth: true,
                    allowRefresh: true,
                    nameForLabels: 'New event',
                    addNewRouteName: 'newCalendarEntry'
                  }
                },
                {
                  name: 'calendarDayViewWithDate',
                  path: '/calendar/day/:startDate',
                  component: CalendarDayView,
                  props: true,
                  meta: {
                    type: constants.viewTypes.day,
                    typeName: constants.viewTypeNames.day,
                    requiresAuth: true,
                    allowRefresh: true,
                    nameForLabels: 'New event',
                    addNewRouteName: 'newCalendarEntry'
                  }
                },
                {
                  name: 'calendarWeekView',
                  path: '/calendar/week',
                  component: CalendarWeekView,
                  props: true,
                  meta: {
                    type: constants.viewTypes.week,
                    typeName: constants.viewTypeNames.week,
                    requiresAuth: true,
                    allowRefresh: true,
                    nameForLabels: 'New event',
                    addNewRouteName: 'newCalendarEntry'
                  }
                },
                {
                  name: 'calendarWeekViewWithDate',
                  path: '/calendar/week/:startDate',
                  component: CalendarWeekView,
                  props: true,
                  meta: {
                    type: constants.viewTypes.week,
                    typeName: constants.viewTypeNames.week,
                    requiresAuth: true,
                    allowRefresh: true,
                    nameForLabels: 'New event',
                    addNewRouteName: 'newCalendarEntry'
                  }
                },
                {
                  name: 'calendarGraveyard',
                  path: '/calendar/deleted',
                  component: CalendarGraveyard,
                  props: true,
                  meta: {
                    type: constants.viewTypes.graveyard,
                    typeName: constants.viewTypeNames.graveyard,
                    requiresAuth: true,
                    allowRefresh: true,
                    nameForLabels: 'New event',
                    addNewRouteName: 'newCalendarEntry'
                  }
                },
                {
                  name: 'calendarGraveyardWithDate',
                  path: '/calendar/deleted/:itemId',
                  component: CalendarGraveyard,
                  props: true,
                  meta: {
                    type: constants.viewTypes.graveyard,
                    typeName: constants.viewTypeNames.graveyard,
                    requiresAuth: true,
                    allowRefresh: true,
                    nameForLabels: 'New event',
                    addNewRouteName: 'newCalendarEntry'
                  }
                }
              ]
            },
            {
              name: 'newCalendarEntry',
              path: '/calendar/entry',
              redirect: (to) => {
                return '/calendar/entry/0'
              }
            },
            {
              name: 'calendarEntryFormNoDate',
              path: '/calendar/entry/:itemId',
              component: CalendarEntryForm,
              props: true,
              meta: {
                type: constants.viewTypes.form,
                typeName: '',
                requiresAuth: true,
                allowRefresh: true
              }
            },
            {
              name: 'calendarEntryForm',
              path: '/calendar/entry/:itemId/:startDate',
              component: CalendarEntryForm,
              props: true,
              meta: {
                type: constants.viewTypes.form,
                typeName: '',
                requiresAuth: true,
                allowRefresh: true
              }
            },
            {
              name: 'calendarEntryFormWithSeries',
              path: '/calendar/entry/:itemId/:startDate/:editingSeries',
              component: CalendarEntryForm,
              props: true,
              meta: {
                type: constants.viewTypes.form,
                typeName: '',
                requiresAuth: true,
                allowRefresh: true
              }
            }
          ]
        },
        {
          name: 'moneyTransferLayout',
          path: 'accountablePayments',
          alias: '',
          component: MoneyTransferLayout,
          props: false,
          meta: {
            requiresAuth: true,
            trackingName: br.appActionFeature.payments
          },
          children: [
            {
              name: 'moneyTransferRoot',
              path: '/accountablePayments/',
              component: MoneyTransfer,
              props: true,
              meta: { requiresAuth: true }
            },
            {
              name: 'moneyTransfer',
              path: '/accountablePayments/',
              alias: '/moneyTransfer',
              component: MoneyTransfer,
              props: true,
              meta: { requiresAuth: true }
            },
            {
              name: 'addTransfer',
              path: '/accountablePayments/addTransfer',
              component: AddTransfer,
              props: true,
              meta: { requiresAuth: true }
            },
            {
              name: 'addRequest',
              path: '/accountablePayments/addRequest',
              component: AddRequest,
              props: true,
              meta: { requiresAuth: true }
            },
            {
              name: 'negativeBalance',
              path: '/accountablePayments/negativeBalance',
              component: NegativeBalance,
              props: true,
              meta: { requiresAuth: true }
            },
            {
              name: 'intro',
              path: '/accountablePayments/intro',
              component: MoneyTransferWelcome,
              props: true,
              meta: { requiresAuth: true }
            },
            {
              name: 'mfaDisabled',
              path: '/accountablePayments/mfaDisabled',
              component: MfaDisabled,
              props: true,
              meta: { requiresAuth: true }
            },
            {
              name: 'verifyCustomer',
              path: '/accountablePayments/verifyCustomer',
              component: VerifyCustomer,
              props: true,
              meta: { requiresAuth: true }
            },
            {
              name: 'verifyMicroDepositPlaid',
              path: '/accountablePayments/verifyMicroDepositPlaid',
              component: verifyMicroDepositPlaid,
              props: true,
              meta: { requiresAuth: true }
            },
            {
              name: 'fundingSourceList',
              path: '/accountablePayments/fundingSourceList',
              component: FundingSourceList,
              props: true,
              meta: { requiresAuth: true }
            },
            {
              name: 'activityList',
              path: '/accountablePayments/activityList',
              component: ActivityList,
              props: true,
              meta: { requiresAuth: true }
            },
            {
              name: 'activityDetails',
              path: '/accountablePayments/activityDetails',
              component: ActivityDetail,
              props: true,
              meta: { requiresAuth: true }
            },
            {
              name: 'expressPayIntro',
              path: '/accountablePayments/expressPayIntro',
              component: ExpressPayIntroPage,
              props: true,
              meta: { requiresAuth: true }
            }
          ]
        },
        {
          name: 'infoLibraryLayout',
          path: 'infoLibrary',
          alias: '',
          component: InfoLibraryLayout,
          props: true,
          meta: {
            requiresAuth: true,
            requiresMatched: true,
            trackingName: br.appActionFeature.infoLibrary
          },
          children: [
            {
              name: 'infoLibrary',
              path: '/infoLibrary/',
              component: InfoLibrary,
              props: true,
              meta: { requiresAuth: true }
            },
            {
              name: 'infoLibrary2',
              path: '/infoLibrary/',
              component: InfoLibrary,
              props: true,
              meta: { requiresAuth: true }
            }
          ]
        },
        {
          name: 'journalLayout',
          path: 'journal',
          alias: '',
          component: JournalLayout,
          props: true,
          meta: {
            requiresAuth: true,
            requiresMatched: true,
            trackingName: br.appActionFeature.journal
          },
          children: [
            {
              name: 'journal',
              path: '/journal/',
              component: Journal,
              props: true,
              meta: {
                requiresAuth: true,
                layoutContainsAds: false,
                nameForLabels: i18n.global.t('journal.newEntryButton'),
                addNewRouteName: 'newJournalEntry'
              }
            },
            {
              name: 'newJournalEntry',
              path: '/journal/entry',
              redirect: (to) => {
                return '/journal/entry/0'
              }
            },
            {
              name: 'journalEntry',
              path: '/journal/entry/:itemId',
              component: JournalForm,
              props: true,
              meta: { requiresAuth: true, layoutContainsAds: true }
            }
          ]
        },
        {
          name: 'recordsLayout',
          path: 'records',
          alias: '',
          component: RecordsLayout,
          props: true,
          meta: {
            requiresAuth: true,
            requiresMatched: true,
            trackingName: br.appActionFeature.records
          },
          children: [
            {
              name: 'recordsRoot',
              path: '/records/',
              component: RecordsHome,
              props: true,
              meta: { requiresAuth: true }
            },
            {
              name: 'recordsHome',
              path: '/records/',
              component: RecordsHome,
              props: true,
              meta: { requiresAuth: true }
            },
            {
              name: 'recordsMain',
              path: '/records/main',
              component: Records,
              props: true,
              meta: { requiresAuth: true }
            },
            {
              name: 'purchase',
              path: '/records/purchase',
              component: RecordsPurchase,
              props: true,
              meta: { requiresAuth: true }
            },
            {
              name: 'printSuccess',
              path: '/records/printSuccess',
              component: PrintedPurchaseSuccess,
              props: true,
              meta: { requiresAuth: true }
            },
            {
              name: 'pdfAccessSuccess',
              path: '/records/pdfAccessSuccess',
              component: PdfPurchaseSuccess,
              props: true,
              meta: { requiresAuth: true }
            }
          ]
        },
        {
          name: 'attachmentsLayout',
          path: 'attachments',
          alias: '',
          component: AttachmentsLayout,
          props: true,
          meta: {
            requiresAuth: true,
            requiresMatched: true,
            trackingName: br.appActionFeature.messages
          },
          children: [
            {
              name: 'attachments',
              path: '/attachments/',
              component: Attachments,
              props: true,
              meta: {
                requiresAuth: true,
                allowRefresh: true,
                layoutContainsAds: false
              }
            }
          ]
        },
        {
          name: 'contactUsLayout',
          path: 'contactus',
          alias: '',
          component: ContactUsLayout,
          props: true,
          meta: { requiresAuth: true, trackingName: 'contactus' },
          children: [
            {
              name: 'contactus',
              path: '/contactus/',
              component: ContactUs,
              props: true,
              meta: {
                requiresAuth: true,
                allowRefresh: true,
                layoutContainsAds: false
              }
            }
          ]
        },
        {
          name: 'vaultLayout',
          path: 'vault',
          alias: '',
          component: VaultLayout,
          props: true,
          meta: { requiresAuth: true, trackingName: 'vault' },
          children: [
            {
              name: 'vault',
              path: '/vault/',
              component: Vault,
              props: true,
              meta: {
                requiresAuth: true,
                allowRefresh: true,
                layoutContainsAds: false
              }
            },
            {
              name: 'vaultShare',
              path: '/vault/share',
              redirect: { path: '/shareVault' }
            }
          ]
        },
        {
          name: 'callingLayout',
          path: 'calling',
          alias: '',
          component: CallingLayout,
          props: true,
          meta: { requiresAuth: true, trackingName: 'calling' },
          children: [
            {
              name: 'calling',
              path: '/calling/',
              component: Calling,
              props: true,
              meta: {
                requiresAuth: true,
                allowRefresh: true,
                layoutContainsAds: false
              }
            },
            {
              name: 'videocall',
              path: '/calling/videocall',
              component: VideoCall,
              props: true,
              meta: {
                requiresAuth: true,
                allowRefresh: true,
                layoutContainsAds: false
              }
            },
            {
              name: 'audiocall',
              path: '/calling/',
              component: Calling,
              props: true,
              meta: {
                requiresAuth: true,
                allowRefresh: true,
                layoutContainsAds: false
              }
            }
            // {
            //   name: 'videoDiagnostics',
            //   path: '/calling/diagnostics',
            //   children: [],
            //   component: VideoDiagnostics,
            //   props: true,
            //   meta: {
            //     requiresAuth: true,
            //     allowRefresh: true,
            //     layoutContainsAds: false
            //   },
            // }
          ]
        }
      ]
    },
    {
      //TODO: fix things broken by renaming this
      name: 'shareVault',
      path: '/shareVault',
      component: LandingPageLayout,
      props: true,
      meta: {
        requiresAuth: false,
        trackingName: br.appActionFeature.vault
      },
      children: [
        {
          name: 'shareVault',
          path: '/shareVault/',
          component: ShareVault,
          props: true,
          meta: { requiresAuth: false }
        }
      ]
    },
    {
      name: 'pdf-download',
      path: '/pdf-download',
      component: LandingPageLayout,
      props: true,
      meta: {
        requiresAuth: false
      },
      children: [
        {
          name: 'PdfDownload',
          path: '/pdf-download',
          component: PdfDownload,
          props: true,
          meta: { requiresAuth: false }
        }
      ]
    },
    {
      name: 'newsletter',
      path: '/newsletter',
      component: LandingPageLayout,
      children: [
        {
          name: 'subscribe',
          path: '/newsletter/subscribe',
          component: NewsletterSubscribe
        },
        {
          name: 'welcome',
          path: '/newsletter/welcome',
          component: NewsletterWelcome
        }
      ]
    },
    {
      name: 'NotFound',
      path: '/:pathMatch(.*)*',
      component: NotFoundLayout,
      children: [
        {
          name: 'notFoundPage',
          path: '/:pathMatch(.*)*',
          component: NotFound,
          meta: {
            requiresAuth: false,
            allowRefresh: true,
            layoutContainsAds: false
          }
        }
      ]
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (from.name == 'viewMessage' && to.name == 'messages') return { top: 0 }
    if (from.name == 'messagesEntry' && to.name == 'messages') return { top: 0 }
    if (!from.path.includes('messages') && to.name == 'messages')
      return { top: 0 }

    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  // check if user is authenticated and that the route requires authentication before routing
  // check if token is being provided in the querystring
  // also check if user is matched and route requires a motch
  //const token = sessionStorage.getItem('user-token')
  const lStore = useLoginStore()
  let token: string | (string | null)[] = lStore.$state.authToken

  if (to.query.z) {
    // parse token to validate
    const _token = to.query.z
    if (typeof _token === 'string') {
      const jwtObj = _token ? decodeJwtService.decodeJwt(_token) : null
      if (jwtObj && jwtObj.UserID) {
        token = _token && _token.length > 0 ? _token : ''
        if (typeof _token === 'string') {
          lStore.setAuthTokenMethod(token)
          lStore.setMfaCookieMethod('')
        }
      }
    }
  }

  if (to.query.x) {
    // parse token to validate
    const _token = to.query.x
    token = _token && _token.length > 0 ? _token : ''
    const payload = { webToggleToken: _token.toString() }
    lStore.webToggleLogin(payload).then(() => {
      next({ path: to.path })
    })
  } else if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    (!token || token == '')
  ) {
    next({
      path: '/login',
      query: {
        returnTo: to.fullPath
      }
    })
  } else {
    const IsItABackButton = window.popStateDetected
    window.popStateDetected = false
    const lcommonStore = useCommonStore()

    if (IsItABackButton && !from?.meta?.allowBack) {
      next(false)
      // } else if (
      //   to.matched.some((record) => record.meta.allowRefresh) &&
      //   localStorage.getItem('newVersionAvailable') === 'true'
      // ) {
      //   window.location.assign(to.path)
      //   if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      //     navigator.serviceWorker.addEventListener('controllerchange', () => {
      //       console.log('Controller has changed.')
      //     })

      //     navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING' })
      //     localStorage.removeItem('newVersionAvailable')

      //     next(false)

      //   }
    } else {
      // check for locale or guess
      if (lcommonStore.userLocale != i18nHelpers.currentLocale) {
        lcommonStore.setUserLocale(i18nHelpers.getGuessDefaultLocale())
      }

      // TODO: uncomment when ready to launch localization
      // load locale messages if different
      // if (i18nHelpers.currentLocale != lcommonStore.userLocale) {
      //   await i18nHelpers.switchLanguage(lcommonStore.userLocale)
      // }

      next()
    }
  }
})

export default router
