﻿<template>
  <input
    ref="codeInput"
    name="codeInput"
    v-model="codeInputValue"
    class="code-input"
    :class="{
      'error-border': verifyPhoneSmsErrorMessage.length || errorStateOverride
    }"
    type="text"
    :placeholder="placeholder"
    autocomplete="off"
    v-maska="maskOptions"
  />
</template>

<script setup lang="ts">
import { useFocus } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, nextTick, onBeforeMount, onMounted, ref, toRefs, watch } from 'vue'
import { useElementVisibility } from '@vueuse/core'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import { vMaska } from 'maska/vue'
import type { MaskInputOptions } from 'maska'
import { useField } from 'vee-validate'

const accountSettingsStore = useAccountSettingsStore()
const { verifyPhoneSmsErrorMessage } = storeToRefs(accountSettingsStore)

const props = withDefaults(
  defineProps<{
    mode?: 'mfa' | 'matchId' | 'email'
    errorStateOverride?: boolean
  }>(),
  {
    mode: 'mfa',
    errorStateOverride: false
  }
)

const { mode, errorStateOverride } = toRefs(props)

const emit = defineEmits<{
  (e: 'codeResult', value: string): void
  (e: 'focusedInput'): void
  (e: 'submit'): void
}>()

const maskPattern = computed(() => {
  if (mode.value == 'matchId') {
    return '***-***'
  }

  if (mode.value == 'email') {
    return '####'
  }

  return '###-###'
})
const placeholder = computed(() => {
  if (mode.value == 'email') {
    return '0000'
  }

  return '000-000'
})

const maskOptions = computed<MaskInputOptions>(() => ({
  mask: maskPattern.value,
  tokens: {
    '*': { pattern: /[a-zA-Z0-9]/, transform: (c) => c.toUpperCase() }
  }
}))

const codeInput = ref<HTMLElement | null>(null)
const { value: codeInputValue } = useField<string>(
  'codeInput',
  (val) => (mode.value == 'email' ? val.length == 4 : val.length == 7),
  {
    initialValue: ''
  }
)
const { focused } = useFocus(codeInput, { initialValue: true })

watch(useElementVisibility(codeInput), async (val: boolean) => {
  if (val) {
    await nextTick()
    focused.value = true
  }
})

watch(focused, (val) => {
  if (val) {
    emit('focusedInput')
  }
})

watch(codeInputValue, (val) => {
  const result = val.replace('-', '')

  emit('codeResult', result)

  //TODO auto-submit
  // if (mode.value == 'email' && result.length == 4) {
  //   emit('submit')

  //   return
  // }

  // if (val.length == 6) {
  //   emit('submit')
  // }
})
</script>

<style lang="scss" scoped>
.code-input {
  width: 100%;
  max-width: 14.375rem;
  height: 100%;
  max-height: 3.75rem;

  padding: 1rem;

  text-align: center;

  font-size: var(--font-size-6);
  font-variation-settings: 'wght' 500;

  border-radius: 0.75rem;
}

.error-border {
  border-color: var(--error-red);
  box-shadow: 0 0 0 2px var(--error-red);
}
</style>
